import React, { useState } from "react";

import DialogComponent from "~layout/dialog";
import RenderImage from "~wrappers/renderImage";
import { RenderMD } from "~wrappers/renderContent";

const ExpertiseComponent = ({ data }) => {
  const [memberDialogState, memberModalTrigger] = useState();
  const [memberDialogContent, setMemberDialogContent] = useState("");
  const [memberDialogTitle, setMemberDialogTitle] = useState("About me");

  const memberDialogInstance = {
    id: "member-dialog",
    title: `About ${memberDialogTitle}`,
    status: memberDialogState,
  };

  return (
    <>
      <section className="stf-container">
        <div className="stf-expertise">
          <div className="columns is-multiline">
            <div className="column is-5 is-full-touch">
              <div className="stf-expertise__content">
                <h2 className="stf-label">{data.sectionName}</h2>
                <h2>{data.title}</h2>
                <RenderMD content={data.text} />
              </div>
            </div>

            <div className="column is-6 is-offset-1 is-full-touch is-offset-0-touch">
              <div className="stf-expertise__avatars">
                {data.team.map((member, index) => (
                  <div key={index} className="stf-expertise__avatar">
                    <div className="stf-expertise__avatar__shadow">
                      <RenderImage
                        image={member.avatar}
                        modifiers={{
                          className: "stf-expertise__avatar__picture",
                        }}
                      />
                    </div>
                    <h3>{member.name}</h3>
                    <p>{member.rank}</p>
                    <button
                      onClick={(e) => {
                        memberModalTrigger(true);
                        setMemberDialogContent(member);
                        setMemberDialogTitle(member.name.replace(/ .*/, ""));
                        e.target.blur();
                      }}
                    >
                      {member.linkText}
                    </button>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>
      <DialogComponent
        data={memberDialogInstance}
        dialogToggle={memberModalTrigger}
      >
        <RenderMD content={memberDialogContent.dialog} />
      </DialogComponent>
    </>
  );
};
export default ExpertiseComponent;
