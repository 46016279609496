import React from "react";
import { graphql } from "gatsby";

// JS logic
import { getLocale } from "~utils";

// Components
import Layout from "~layout";
import CoverageComponent from "~components/home/coverage";
import GuidesComponent from "~components/home/guides";
import ExpertiseComponent from "~components/home/expertise";
import WorksComponent from "~components/home/works";
import MobileAppComponent from "~components/home/mobileApp";
import PeekComponent from "~components/common/peek";

const IndexPage = ({ data, location }) => {
  const {
    locale: localeKey,
    metadata,
    headerOverride,
    coverage,
    works,
    expertise,
    guides,
    mobileApp,
    peek,
  } = data.markdownRemark.frontmatter;

  const locale = getLocale(localeKey);

  return (
    <Layout
      location={location}
      locale={locale}
      pageMetadata={metadata}
      pageHeader={headerOverride}
    >
      <CoverageComponent data={coverage} locale={locale} />
      <WorksComponent data={works} />
      <ExpertiseComponent data={expertise} />
      <GuidesComponent data={guides} />
      <MobileAppComponent data={mobileApp} />
      <PeekComponent data={peek} location={location} locale={locale} />
    </Layout>
  );
};

export default IndexPage;

export const pageQuery = graphql`
  query IndexPage($locale: String) {
    markdownRemark(
      frontmatter: {
        templateKey: { eq: "index-page" }
        locale: { eq: $locale }
      }
    ) {
      frontmatter {
        locale
        metadata {
          title
          description
        }
        headerOverride {
          img {
            name
            childImageSharp {
              fluid(maxWidth: 1080, quality: 90) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          title
          text
          button {
            text
            path
          }
        }
        coverage {
          sectionName
          title
          text
          button {
            text
            path
          }
          qualitiesIcon {
            name
            publicURL
          }
          qualities {
            bold
            regular
          }
        }
        works {
          sectionName
          title
          text
          wizard {
            text
            image {
              name
              publicURL
            }
          }
        }
        expertise {
          sectionName
          title
          text
          team {
            avatar {
              name
              publicURL
            }
            name
            rank
            link
            linkText
            dialog
          }
        }
        guides {
          title
          text
          icon {
            name
            publicURL
          }
          img {
            name
            childImageSharp {
              fluid(maxWidth: 1080, quality: 90) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          dialog
        }
        mobileApp {
          sectionName
          title
          text
          img {
            name
            childImageSharp {
              fluid(maxWidth: 500, quality: 90) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          iconGoogle {
            img {
              name
              publicURL
            }
            link
          }
          iconApple {
            img {
              name
              publicURL
            }
            link
          }
        }
        peek {
          sectionName
          title
          text
          img {
            name
            childImageSharp {
              fluid(maxWidth: 1080, quality: 90) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          button {
            text
            path
          }
          textUnder
        }
      }
    }
  }
`;
