import React from "react";
import { Link } from "gatsby";

import RenderImage from "~wrappers/renderImage";
import { RenderMD } from "~wrappers/renderContent";
import { localizedUrl } from "~utils";

const CoverageComponent = ({ data, locale }) => {
  return (
    <section className="stf-coverage">
      <div className="stf-container">
        <div className="columns">
          <div className="column">
            <h2 className="stf-label">{data.sectionName}</h2>
            <h2>{data.title}</h2>
          </div>
        </div>
        <div className="columns columns-reversed is-variable is-8-tablet">
          <div className="column is-half">
            <RenderMD content={data.text} />
            <Link
              to={localizedUrl(locale, data.button.path)}
              className="stf-button stf-button--ghost-black"
            >
              {data.button.text}
            </Link>
          </div>

          <div className="column is-half">
            <div className="stf-coverage__qualities">
              {data.qualities.map((quality, index) => (
                <div className="stf-coverage__quality" key={index}>
                  <RenderImage
                    image={data.qualitiesIcon}
                    modifiers={{
                      className: "stf-coverage__quality__icon",
                    }}
                  />
                  <span>
                    <strong>{quality.bold}</strong>
                    {quality.regular}
                  </span>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
export default CoverageComponent;
