import React from "react";

import RenderImage from "~wrappers/renderImage";
import { RenderMD } from "~wrappers/renderContent";

const WorksComponent = ({ data }) => {
  return (
    <section className="stf-works">
      <div className="stf-container">
        <div className="stf-works__content">
          <div className="columns">
            <div className="column is-10-desktop">
              <h2 className="stf-label">{data.sectionName}</h2>
              <h2>{data.title}</h2>
              <div className="stf-works__text">
                <RenderMD content={data.text} />
              </div>
            </div>
          </div>

          <div className="stf-works__wizard">
            <div className="columns is-variable is-8-tablet">
              {data.wizard.map((step, index) => (
                <div className="column" key={index}>
                  <RenderImage
                    image={step.image}
                    modifiers={{
                      className: "stf-works__wizard__image",
                    }}
                  />
                  <RenderMD content={step.text} />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
export default WorksComponent;
