import React from "react";

import RenderImage from "~wrappers/renderImage";
import { RenderMD } from "~wrappers/renderContent";

const MobileAppComponent = ({ data }) => {
  return (
    <section className="stf-container">
      <div className="stf-mobileapp">
        <div className="columns is-variable is-8-tablet">
          <div className="column is-6 is-full-tablet">
            <div className="stf-mobileapp__image">
              <RenderImage image={data.img} />
            </div>
          </div>

          <div className="column is-6 is-full-tablet">
            <div className="stf-mobileapp__content">
              <h2 className="stf-label">{data.sectionName}</h2>
              <h2>{data.title}</h2>
              <RenderMD content={data.text} />

              <div className="stf-mobileapp__badges">
                <div className="stf-mobileapp__badge">
                  <a href={data.iconGoogle.link} target="_blank" rel="noreferrer">
                    <RenderImage image={data.iconGoogle.img} />
                  </a>
                </div>
                <div className="stf-mobileapp__badge">
                  <a href={data.iconApple.link} target="_blank" rel="noreferrer">
                    <RenderImage image={data.iconApple.img} />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
export default MobileAppComponent;
