import React from "react";
import { Link } from "gatsby";

import RenderImage from "~wrappers/renderImage";
import { RenderMD } from "~wrappers/renderContent";

const GuidesComponent = ({ data }) => {
  return (
    <section className="stf-container">
      <Link className="stf-guides" to={"/our-work/"}>
        <RenderImage
          image={data.img}
          modifiers={{
            className: "stf-guides__background",
          }}
        />

        <div className="stf-guides__content">
          <div className="stf-guides__content__icon">
            <RenderImage
              image={data.icon}
              modifiers={{
                className: "stf-guides__content__icon__image",
              }}
            />
          </div>
          <div className="stf-guides__content__text">
            <h2>{data.title}</h2>
            <RenderMD content={data.text} />
          </div>
        </div>
      </Link>
    </section>
  );
};
export default GuidesComponent;
